@import "~antd/dist/antd.css";
.App {
  text-align: center;
  // background-color: #f7f7f7;
  min-height: 100vh;

  /* */
  .label {
    padding: 0 10px;
  }
  .value {
    padding: 0 10px;
  }

  .main-section {
    height: calc(100vh - 80px);
    overflow: auto;
  }
  .site-page-header {
    padding-left: 0;
  }
  .hide-section {
    display: none;
  }
}
