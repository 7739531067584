.header {
  color: white;
  .logo {
    min-width: 60%;
    float: left;
    color: white;
    &-text {
      float: left;
      color: white;
      cursor: pointer;
    }
  }
  .nav-menu {
    min-width: 40%;
    // min-width: 600px;
    //display: flex;
    justify-content: flex-end;
  }
}
